.title-menu {
  color: #acb5bd;
}

.title-dialog {
  color: black;
}

.divider-menu {
  background-color: #acb5bd !important;
  margin-top: 15px !important;
}

.divider-dialog {
  background-color: black !important;
  margin-top: 22px !important;
}
