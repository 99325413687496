.MuiDataGrid-columnHeaderTitle {
  text-transform: uppercase;
}

.MuiDataGrid-cell--withRenderer {
  line-height: normal !important;
}

.statusDate {
  font-size: x-small;
  font-weight: lighter;
}

.statusCapsule {
  border-radius: 10px;
  padding: 3px 5px;
  background-color: #bbbbbb88;
  font-size: x-small;
  margin-bottom: 3px;
}

.statusCapsuleDot {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.contract-link {
  color: #f39501 !important;
}
