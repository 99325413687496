.loginContainer {
  height: 100vh;
}

.loginVerticalBanner {
  height: 100vh;
  width: 100%;
  border-radius: 0 !important;
  background-color: #f39501 !important;
  background: linear-gradient(360deg, rgba(233, 78, 27, 0.51) 0%, #ed9e26 100%);
}
