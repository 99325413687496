.step-text {
  font-size: 26px;
  font-family: Roboto;
  margin-bottom: 1em;
}

.step-text::first-letter {
  text-transform: capitalize;
}

.last-step {
  color: #8a8a8a;
  cursor: pointer;
}
