.navBarContainer {
  height: 30px;
  width: 100%;
  padding: 10px;
  border-radius: 0px !important;
  background-color: #f39501 !important;
  background: linear-gradient(267.45deg, rgba(233, 78, 27, 0.51) 5.66%, #ed9e26 97.9%);
}

.navBarLogo {
  margin: 5px 0px 0px 10px;
  height: 20px;
}
